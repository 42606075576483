import React from 'react';
import { getLocationProducts } from '../../../utils/map';
import { useTranslation } from 'react-i18next';

const LocationProduct = ({ id, value }) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-max-w-[80px]">
      <img
        src={`../../../img/service-icons/${id}.svg`}
        alt={value}
        className="tw-h-10"
      />
      <span className="tw-text-xs tw-font-bold tw-text-center tw-text-gray-700 tw-uppercase">
        {value}
      </span>
    </div>
  );
};
const LocationProductList = ({ location }) => {
  const { t, i18n } = useTranslation();
  const products = getLocationProducts(location);
  return (
    <div>
      {!!products.length && (
        <div className="tw-mt-8">
          <h2 className="tw-text-lg tw-text-blue tw-uppercase">
            {t('Products')}
          </h2>
          <div className="tw-gap-8 tw-flex tw-mt-4">
            {products.map((product) => (
              <LocationProduct
                key={product.enId}
                id={
                  i18n.resolvedLanguage === 'en' ? product.enId : product.frId
                }
                value={t(product.enValue)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationProductList;
